import React, { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
	const [cpi, setCpi] = useState();
	const [categoriesCpi, setCategoriesCpi] = useState();
	const [gdp, setGdp] = useState();
	// const [errors, setErrors] = useState();
	const [unemployment, setUnemployment] = useState();
	const [interestRate, setInterestRate] = useState();
	const [fxRate, setFxRate] = useState();
	const [latestData, setLatestData] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [portfolio, setPortfolio] = useState({ byDate: [], byName: [] });

	useEffect(() => {
		const abortController = new AbortController();

		async function getData() {
			try {
				await Promise.all([
					fetch("/data/cpi/cnb.json")
						.then((response) => response.json())
						.then(function (data) {
							setCpi(data);
						}),
					fetch("/data/cpi/csu.json")
						.then((response) => response.json())
						.then((data) => setCategoriesCpi(data)),

					fetch("/data/gdp/gdp.json")
						.then((response) => response.json())
						.then(function (data) {
							setGdp(data);
						}),

					fetch("/data/interest/rates.json")
						.then((response) => response.json())
						.then((data) => setInterestRate(data)),

					fetch("/data/unemployment/rate.json")
						.then((response) => response.json())
						.then((data) => setUnemployment(data)),

					fetch("/data/fx/rates.json")
						.then((response) => response.json())
						// .then(data => data.reverse())
						.then((data) => setFxRate(data)),

					// fetch("/data/portfolio/by-date.json")
					// 	.then((response) => response.json())
					// 	.then((data) => setPortfolio({ byDate: data })),

					// fetch("/data/portfolio/by-name.json")
					// 	.then((response) => response.json())
					// 	.then((data) => setPortfolio({ byName: data })),
				])
					.then(() => {
						setLoaded(true);
					})
					.catch((error) => {
						console.error(error);
					})
					.finally(() => {
						console.log("Done");
					});

				const [byDateData, byNameData] = await Promise.all([
					fetch("/data/portfolio/by-date.json").then((response) =>
						response.json()
					),
					fetch("/data/portfolio/by-name.json").then((response) =>
						response.json()
					),
				]);

				// Update the state with the combined data
				setPortfolio({
					byDate: byDateData,
					byName: byNameData,
				});
			} catch (error) {
				console.log(error);
			}
		}

		getData();
		// async function fetchCpi() {
		// 	await fetch('/cpi.json')
		// 		.then(response => response.json())
		// 		.then(data => setCpi(data))
		// }

		// async function fetchCategoriesCpi() {
		// 	await fetch('/cpi_categories.json')
		// 		.then(response => response.json())
		// 		.then(data => setCategoriesCpi(data))
		// }

		// async function fetchGdp() {
		// 	await fetch('/gdp.json')
		// 		.then(response => response.json())
		// 		.then(data => setGdp(data),
		// 			// (error) => {
		// 			// 	setErrors(error)
		// 			// }
		// 		)
		// }

		// async function fetchInterestRate() {
		// 	await fetch('/interest-rate.json')
		// 		.then(response => response.json())
		// 		.then(data => setInterestRate(data))
		// }

		// async function fetchUnemployment() {
		// 	await fetch('/unemployment.json')
		// 		.then(response => response.json())
		// 		.then(data => setUnemployment(data));
		// }

		// async function fetchFxRate() {
		// 	await fetch('/fx-rate.json')
		// 		.then(response => response.json())
		// 		.then(data => setFxRate(data.reverse()))
		// }

		// fetchCpi();
		// fetchCategoriesCpi();
		// fetchGdp();
		// fetchUnemployment();
		// fetchInterestRate();
		// fetchFxRate();

		return () => {
			abortController.abort();
		};
	}, []);

	return (
		<AppContext.Provider
			value={{
				cpi,
				categoriesCpi,
				gdp,
				// errors,
				unemployment,
				interestRate,
				fxRate,
				latestData,
				loaded,
				portfolio,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export function useApi() {
	const context = useContext(AppContext);

	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}

	return context;
}
