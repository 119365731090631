import { RouterProvider } from 'react-router-dom';
import { AppContextProvider } from './context/appContext';

import router from './routes';

function App() {
  return (
      <AppContextProvider>
        <RouterProvider router={router} />
      </AppContextProvider>
  );
}

export default App;
