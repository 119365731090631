export default function LatestCommonData(props) {

	const { data } = props;

	return data && data.map((item, key) => (
		<div key={key} className={"d-flex flex-row justify-content-between border-bottom py-1 align-items-center " + (key === 5 ? '' : '')}>
			<span>{item.date}</span>
			<div className="d-flex flex-column align-items-end">
				<span>
					{Math.round(item.cpi * 10) / 10} %
				</span>
				<span>
					{Math.round(item.core * 10) / 10} %
				</span>
			</div>
		</div>
	))
}