import * as d3 from "d3";
import { useEffect, useRef } from "react";

function Chart(props) {

	const margins = {
		left: 0,
		right: 50,
		top: 20,
		bottom: 30,
	}

	const {
		width = 500,
		height = 600,
		data
	} = props;

	const ref = useRef();
	const divRef = useRef();

	useEffect(() => {

		const rates = data.slice(0, 240).map((item) => {

			return {
				date: parseDate(item.date),
				value: item.value
			}
		})

		// const o = Array.from(
		// 	(function* () {
		// 		let date = new Date("2023-01-01"),
		// 			close = 98.84;
		// 		for (let i = 0; i < 5; i++) {
		// 			if (i % 7 > 5) continue; // ignore 2 days per week
		// 			date = new Date(date.setDate(date.getDate() + 1));
		// 			close = +(close + Math.random() - 0.485).toFixed(2);
		// 			yield { date, close };
		// 		}
		// 	})()

		// )

		const svg = d3.select(ref.current)
			// .attr('width', width + margins.left + margins.right)
			// .attr('height', height + margins.bottom + margins.top)
			// .attr('transform', `translate(${margins.left}, ${margins.right})`)
			.attr('viewBox', [0, 0, width, height])
			.style('overflow', 'visible')
			;

		const x = d3.scaleUtc()
			.domain(
				d3.extent(rates, function (d) {
					return d.date
				})
			)
			// .nice()
			.range([margins.left, width - margins.right]);

		const xAxis = svg => svg
			.attr('transform', `translate(0, ${height - margins.top})`)
			.call(
				d3.axisBottom(x)
				// .ticks(d3.timeMonth.every(3))
				// .ticks(10)
				// .tickFormat(d => d <= d3.timeYear(d) ? d.getFullYear() : null)
			)
			.call(g => g.select('.domain').remove())
			;

		const y = d3.scaleLinear()
			.domain([0, d3.max(rates, function (d) {
				return d.value;
			}) + 1])// .nice()
			.range([height - margins.top, margins.bottom])
			;

		const yAxis = svg => svg
			.attr('transform', `translate(${margins.left}, 0)`)
			.call(
				d3.axisRight(y)
					// .ticks(16)
					.tickSize(width)
			)
			.call(
				g => g.select('.domain').remove()
			)
			.call(
				g => g
					.selectAll('.tick:not(:first-of-type) line')
					.attr('stroke-opacity', 0.8)
					.attr('stroke-dasharray', 2.2)
			)
			.call(
				g => g.selectAll('.tick text')
					.attr('x', 4)
					.attr('dy', -4)
			)
			;

		svg.append('g')
			.call(xAxis);

		svg.append('g')
			.call(yAxis);

		svg.append('path')
			.datum(rates)
			.attr('fill', 'none')
			.attr('stroke', 'orange')
			.attr('stroke-width', 2)
			.attr('d', d3.line()
				.curve(d3.curveLinear)
				.x(function (d) {
					return x(d.date)
				})
				.y(function (d) {
					return y(d.value)
				})
			);

		const X = d3.map(rates, (rate) => rate.date);
		const Y = d3.map(rates, (rate) => rate.value);
		const O = d3.map(rates, rate => rate);
		const I = d3.map(rates, (_, i) => i);

		X.reverse();
		Y.reverse();

		const tooltip = svg.append("g")
			.style("pointer-events", "none");

		function moved(event) {
			const pointedDate = x.invert(d3.pointer(event)[0]);
			const firstDateOfMonth = pointedDate.setDate(1);

			const i = d3.bisectCenter(X, new Date(firstDateOfMonth));

			const xValue = X[i];
			const yValue = Y[i];

			// console.log(xValue, yValue);

			if (xValue && yValue) {
				tooltip.style('display', null);
				tooltip.attr('transform', `translate(${x(xValue)}, ${y(yValue)})`);

				const path = tooltip.selectAll('path')
					.data([,])
					.join('path')
					.attr('fill', 'white')
					.attr('stroke', 'black');

				const tooltipValue = [
					(xValue.getMonth() + 1) + '/' + xValue.getFullYear(),
					yValue + '%'
				];

				const text = tooltip.selectAll('text')
					.data([,])
					.join('text')
					.call(
						text => text
						.selectAll('tspan')
						.data(tooltipValue)
						.join('tspan')
							.attr('x', 0)
							.attr('y', (_, i) => `${i * 1.1}em`)
							.attr('font-weight', (_, i) => i ? null : 'bold')
							.attr('font-size', '13px')
							.attr('z-index', 1000)
							.text(d => d)
						);
				
				const { x: xBox, y: yBox, width: w, height: h } = text.node().getBBox();

				
				text.attr('transform', `translate(${-w / 2}, ${7 - yBox})`);
				path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 7}h-${w + 20}z`);
				svg.property('value', O[i]).dispatch('input', { bubles: true })
			}


			/* if (value !== undefined) {
				const path = tt.selectAll('path')
					.data([,])
					.join('path')
					.attr('fill', 'white')
					.attr('stroke', 'black');

				const text = tt.selectAll('text')
					.data([,])
					.join('text')
					.call(text => text
						.selectAll('tspan')
						.data(`${value}`)
						.join('tspan')
						.attr('x', 0)
						.attr('y', (_, i) => `${i * 1.1}em`)
						.attr('font-weight', (_, i) => i ? null : 'bold')
						.text(d => d)
					)

				const { xBox, yBox, width: w, height: h } = text.node().getBBox();

				text.attr('transform', `translate(${-w/2}, ${15 - yBox})`);
				path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
				svg.property('value', O[i]).dispatch('input', { bubles: true })
			} */

		}

		svg.on("pointermove", moved)
			// .on("mousemove", mousemove)
			// .on("mouseleave", mouseleave)
			;

	})

	return (
		<div style={{ position: 'relative', minHeight: '1.5rem' }} ref={divRef}>
			<svg ref={ref} />
		</div>
	)
}

const bisect = d3.bisector(function (d) {
	return d.date
});

function getUniqueNeighbors(arr) {
	const uniqueNeighbors = [];
	for (let i = 0; i < arr.length; i++) {
		const curr = arr[i];
		const prev = arr[i - 1];
		const next = arr[i + 1];
		if ((prev && prev.value !== curr.value) || (next && next.value !== curr.value)) {
			uniqueNeighbors.push(curr);
		}
	}
	return uniqueNeighbors;
}

function getUniqueValues(arr) {
	const uniqueValues = arr.reduce((acc, curr) => {
		if (!acc.includes(curr.value)) {
			acc.push(curr.value);
		}
		return acc;
	}, []);
	return uniqueValues;
}


function parseDate(dateString) {
	let [month, year] = dateString.split('/');

	let date = new Date(Date.UTC(year, month - 1, 1));

	return date;
}

export default Chart;