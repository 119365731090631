import { Placeholder } from "react-bootstrap"

export default function LatesDataNoItem(props) {
	const { counter } = props

	let elements = []

	for (let i = 0; i < counter; i++) {
		elements.push(
			<div className='col-md-3' key={`no-item-${i}`}>
				<div className="d-flex flex-column">
					<div
						className="
								bg-dark
								text-white
								my-2 
								rounded-1 											
								d-flex 
								justify-content-between 
								px-3 
								py-3 
								align-items-center"
					>
						<div className="d-flex flex-column placeholder-wave w-100">
							<div className="my-2 d-flex justify-content-between">
								<Placeholder as="span" lg={8} /> 
								<Placeholder as="span" lg={3} />
							</div>
							<div className="my-2 d-flex justify-content-between">
								<Placeholder as="span" lg={3} /> 
								<Placeholder as="span" lg={8} />
							</div>
							<div className="my-2 d-flex justify-content-between">
								<Placeholder as="span" lg={8} /> 
								<Placeholder as="span" lg={3} />
							</div>
						</div>

					</div>
				</div>
			</div>
		)
	}

	return (
		elements
	)
}