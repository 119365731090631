export default function TableData(props) {

	const { data } = props;

	return data && data.map((item, key) => (
		<div key={key} className={"d-flex flex-row justify-content-between border-bottom py-1 align-items-center " + (key === 5 ? '' : '')}>
			{/* <span>{item.date.replace(/(\d{2})\/(\d{4})/, "$1 $2")}</span> */}
			{item.date}
			<div className="d-flex flex-column align-items-end">
				<span>
					{item.value} %
				</span>
			</div>
		</div>
	))
}