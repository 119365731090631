import { useState } from "react";
// import { useAppContext } from "../context/appContext";

export default function Sup() {
	const rates = [];

	useState(() => {
		console.log(rates);
	})

	return (
		<div>
			<div>
				{Object.values(rates).map(item => (
					<div key={item.id}>
						{item.value}
					</div>
				))}
			</div>
		</div>
	)
}