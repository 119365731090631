import * as d3 from "d3";
import { useEffect, useState } from "react";

export default function LatestCategoriesData(props) {

	const [items, setItems] = useState([]);

	const { data } = props;

	function getColor(index) {
		let scale = d3.scaleOrdinal(Object.keys(items).filter(item => !["Total", "reportDate"].includes(item)), d3.schemePaired);

		
		return scale(index);
	}
	
	useEffect(() => {
		let latest = Object.values(data).slice(-1).shift();
		
		setItems(latest);
	}, [])

	return (
		Object.keys(items).filter(item => !["Total", "reportDate"].includes(item)).map((item, key) => {

			return (
				<div className={"small text-dark d-flex flex-row justify-content-between px-3 py-1 align-items-center " + (false ? 'rounded-bottom' : '')} key={key}
				style={{backgroundColor: getColor(item)}}
				>
					<span className="col-9">{item}</span>
					<span className="col-3 d-flex justify-content-end">{Math.floor(items[item] - 100)} %</span>
				</div>
			)
		})
	)
}