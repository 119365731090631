import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../../context/appContext';
import LatestDataItem from './LatestDataItem';
import LatesDataNoItem from './LatestDataNoItem';

function Home() {

	const { cpi, gdp, unemployment, interestRate, fxRate, loaded } = useApi();

	const [latestData, setLatestData] = useState();

	useEffect(() => {

		if (loaded) {
			setLatestData({
				gdp: {
					value: gdp?.yoy?.slice(-1)?.shift()?.value,
					date: gdp?.yoy?.slice(-1)?.shift()?.period.replace(/(\d{4})([A-Z]\d)/, "$1 $2")
				},
				cpi: {
					date: cpi?.slice(0, 1)?.shift()?.date,
					value: cpi?.slice(0, 1)?.shift()?.cpi
				},
				unemployment: {
					value: unemployment?.slice(0, 1)?.shift()?.value,
					date: unemployment?.slice(0, 1)?.shift()?.period.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2/$1"),
				},
				interestRate: {
					value: interestRate?.slice(0, 1)?.shift()?.value,
					date: interestRate?.slice(0, 1)?.shift()?.date,
				},
				fxRate: {
					value: fxRate?.toReversed().slice(-1)?.shift()?.value,
					date: fxRate?.toReversed().slice(-1)?.shift()?.date.replace(/(\d{1,2})\/(\d{1,2})\/(\d{4})/, "$1/$3")
				}
			})
		} else {
			console.info('waiting...')
		}

	}, [cpi, gdp, unemployment, interestRate, fxRate, loaded])

	return (
		<>
			<div className="bg-dark">
				<img className="img-fluid position-absolute end-0" src="/images/hero/hero-bg.png" alt="" />
				<section>

					<div className="container">
						<div className="row align-items-center py-lg-5 pt-9 pb-4">
							<div className="col-lg-6 text-center text-lg-start">
								<h1 className="text-white fs-xl-5">
									Save Your Time Analyzing Economy of the Czech Republic
								</h1>
								<p className="text-white py-lg-3 py-2">
									Stay informed about the Czech Republic business environment with our website, offering in-depth economic analysis, market trends and investment opportunities.
								</p>
								<div className="d-sm-flex justify-content-center justify-content-lg-start align-items-center gap-3">
									<a href="#latestData" className="btn btn-outline-light mb-3 w-75">Explore</a>
								</div>
							</div>
							<div className="col-lg-6 text-center text-lg-end mt-3 mt-lg-0"><img className="img-fluid" src="/images/hero/hero-graphics.png" alt="" /></div>
						</div>
					</div>


				</section>
			</div>

			<section className="py-lg-6 bg-light text-dark">

				<div className="container">
					<h2 id="latestData" className="mx-auto text-center fs-lg-6 fs-md-5 w-lg-75">Latest macroeconomic data</h2>
					<div className="row mt-5 gx-xl-5">
						{latestData ? (
							<>
								<LatestDataItem
									data={latestData.cpi ? latestData.cpi.value : 0}
									title={"CPI"}
									// subtitle={"Consumer Price Index"}
									subtitle={"Report date: " + (latestData.cpi ? latestData.cpi.date : '')}
									suffix='%'
									linkTo={'/cpi'}
								/>
								<LatestDataItem
									data={latestData.gdp ? latestData.gdp.value : 0}
									title={"GDP"}
									// subtitle={"Gross Domestic Produce"}
									subtitle={"Report date: " + (latestData.gdp ? latestData.gdp.date : '')}
									suffix='%'
									linkTo={'/gdp'}
								/>
								<LatestDataItem
									data={Math.round(latestData.unemployment.value * 100) / 100}
									title={"Unemployment"}
									// subtitle={"Unemployment Rate"}
									subtitle={"Report date: " + (latestData.unemployment.date ? latestData.unemployment.date : '')}
									suffix='%'
									linkTo={'/unemployment'}
								/>
								<LatestDataItem
									data={latestData.interestRate ? latestData.interestRate.value : 0}
									title={"Interest"}
									// subtitle={"2W Repo Rate"}
									subtitle={"Report date: " + (latestData.interestRate.date ? latestData.interestRate.date : '')}
									suffix='%'
									linkTo={'/interest-rate'}
								/>
							</>
						) : (
							<LatesDataNoItem counter={4} />
						)}
					</div>
				</div>
				<div className="container mt-5">
					<h3 className="h4 mx-auto text-center fs-lg-5 fs-md-5 w-lg-75">Exchange Rates</h3>
					<p className="mx-auto text-center small">End of the previous month</p>

					<div className="row mt-5 gx-xl-5 justify-content-around">
						{latestData ? (
							<>
								<LatestDataItem
									data={Math.round(latestData.fxRate?.value.usd * 100) / 100}
									title={"USD"}
									// subtitle={"End of the previous month"}
									subtitle={"Report date: " + (latestData.fxRate.date ? latestData.fxRate.date : '')}
									suffix=' CZK'
									linkTo={'/fx-rate'}
								/>
								<LatestDataItem
									data={Math.round(latestData.fxRate?.value.eur * 100) / 100}
									title={"EUR"}
									// subtitle={"End of the previous month"}
									subtitle={"Report date: " + (latestData.fxRate.date ? latestData.fxRate.date : '')}
									suffix=' CZK'
									linkTo={'/fx-rate'}
								/>
							</>
						) : (
							<LatesDataNoItem counter={2} />
						)}
					</div>

				</div>
			</section>

			<section className="pt-lg-0 pb-0 bg-dark">
				<div className="py-4 container">

					<h1 className="text-center fs-lg-4 fs-md-3 fs-xl-5 mb-5 text-white">
						Analyze. Always. Anywhere.
					</h1>

					<div className="d-flex flex-column-reverse flex-lg-row">
						<div className="col-lg-8 pt-5 pt-lg-0">
							<ul className="list-unstyled">
								<li className="mb-lg-5 mb-5 fs-4 shadow-sm text-white d-flex flex-row justify-content-between align-items-lg-center">
									<img className="w-25 me-3" src="/images/czechstats/app8.svg" alt="source: undraw.co" />
									<div className="d-flex flex-column">
										<span>Stay informed</span>
										<span className="fw-lighter small">
											Our app provides up-to-date information on the macroeconomic indicators of the Czech Republic.
											Stay informed about the lates trends and developments in the economy.
										</span>
									</div>
								</li>
								<li className="mb-lg-5 mb-5 fs-4 shadow-sm text-white d-flex flex-row justify-content-between align-items-lg-center">
									<img className="w-25 me-3" src="/images/czechstats/app12.svg" alt="source: undraw.co" />
									<div className="d-flex flex-column">
										<span>Save your time</span>
										<span className="fw-lighter small">
											Instead of spending hours researching macroeconomic data,
											you can access all the relevant information in one place through your app.
										</span>
									</div>
								</li>
								<li className="mb-lg-5 mb-5 fs-4 shadow-sm text-white d-flex flex-row justify-content-between align-items-lg-center">
									<img className="w-25 me-3" src="/images/czechstats/app13.svg" alt="source: undraw.co" />
									<div className="d-flex flex-column">
										<span>Free and reliable data</span>
										<span className="fw-lighter small">
											Our app provides free and reliable data from trusted sources,
											ensuring that users have access to accurate and up-to-date information.
										</span>
									</div>
								</li>

								<li className="mt-5 mb-lg-3 mb-5 fs-4 text-white d-flex flex-lg-row flex-column justify-content-lg-start align-items-center">
									<Link className="" to="https://apps.apple.com/cz/app/czech-stats/id1669200005" target="_blank">
										<img src="/images/czechstats/apple-store_white.svg" alt="Mobile app" />
									</Link>
								</li>

							</ul>
						</div>
						<div className="col-lg-4 text-center text-lg-center">
							<img className="w-lg-75 img-fluid border rounded-5" src="/images/ios.png" alt="" style={{ width: '40%' }} />
						</div>
					</div>
				</div>
			</section>

			<section className="py-lg-6 bg-light text-dark">
				<div className="container">
					<h2 className='text-center mb-5'>Our sources</h2>
					<div className='d-flex flex-lg-row flex-column justify-content-around align-items-center my-4'>
						<div className='col-md-4 p-3 rounded text-md-start text-center'>
							<Link to={'https://cnb.cz/'} target='_blank'>
								<img className='w-50 w-md-100' src="/images/brands/cnb.svg" alt="Czech National Bank" />
							</Link>
						</div>
						<div className="col">

							<span>The CNB is the central bank of the Czech Republic, the supervisor of the Czech financial market and the Czech resolution authority. It is established under the Constitution of the Czech Republic and carries out its activities in compliance with Act No. 6/1993 Coll., on the Czech National Bank, as amended.
							</span>
						</div>
					</div>
					<div className='d-flex flex-lg-row flex-column justify-content-around align-items-center my-4'>
						<div className='col-md-4 p-3 rounded text-md-start text-center'>
							<Link to={'https://www.czso.cz/'} target='_blank'>
								<img className='w-50 w-md-100' src="/images/brands/csu.svg" alt="Czech Statistical Organzation" />
							</Link>
						</div>
						<div className="col">
							<span>
								The Czech Statistical Office (CZSO) is a central body of the state administration of the Czech Republic. It was established on 8 January 1969 by the Act No 2/1969 Sb., passed by the Czech National Council, on establishment of ministries and other institutions of central government of the Czech Republic.
							</span>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Home;