import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function Header(props) {

	const navLink = 'nav-link px-3 py-3 ';
	const navbarBackgroundColor = props.scrolled ? 'rgba(24, 25, 30, 0)' : 'rgba(24, 25, 30, 0)';

	const [menuCalled, setMenuCalled] = useState(false)
	const [currentRoute, setCurrentRoute] = useState()

	const location = useLocation()
	const { screen } = window;

	// console.log()

	useEffect(() => {
		if (!currentRoute || currentRoute !== location.pathname) {
			setCurrentRoute(location.pathname)
			setMenuCalled(false)
		}
	}, [location, menuCalled])

	function openMenu() {
		setMenuCalled(!menuCalled)
	}

	return (
		<nav

			className={`navbar navbar-expand-lg navbar-dark ${menuCalled ? 'bg-dark shadow-transition' : ''}`}
			data-navbar-on-scroll="data-navbar-on-scroll"
			style={{
				backgroundImage: 'none',
				backgroundColor: navbarBackgroundColor,
				left: 0,
				right: 0,
				zIndex: 1000,
				position: 'absolute',
				transition: menuCalled ? 'background-color 0.2s ease 0s' : 'none 0s ease 0s',
				borderBottom: menuCalled ? '2px solid orange': 'none'
			}}
		>
			<div className="container justify-content-between">
				<Link to={'/'} className={'navbar-brand m-lg-auto m-0'}>
					{/* transition: all linear .5s */}
					<img src='/logo_short_2.svg' alt="Logo" />
					{/* <img src={props.scrolled ? '/icon.svg' : '/logo_short_2.svg'} alt="Logo" width={props.scrolled ? 50: 250} /> */}
					{/* Czech Stats */}
				</Link>

				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={openMenu}>
					<i className="fa-solid fa-bars text-white"></i>
				</button>

				<div className={`collapse navbar-collapse ${menuCalled ? 'show' : ''}`} id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto mt-2 mt-lg-0">
						<li className="nav-item">
							<Link to={'/'} className={"nav-link px-3 py-3"}>
								Home
							</Link>
						</li>
						<li className="nav-item">
							<NavLink to={'cpi'} className={({ isActive, isPending }) =>
								isActive ? `${navLink + 'active'}` : isPending ? `${navLink + 'pending'}` : `${navLink}`
							}>
								CPI
							</NavLink>
						</li>

						<li className="nav-item">
							<NavLink to={'gdp'} className={({ isActive, isPending }) =>
								isActive ? `${navLink + 'active'}` : isPending ? `${navLink + 'pending'}` : `${navLink}`
							}>
								GDP
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={'unemployment'} className={({ isActive, isPending }) =>
								isActive ? `${navLink + 'active'}` : isPending ? `${navLink + 'pending'}` : `${navLink}`
							}>
								Unemployment Rate
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={'interest-rate'} className={({ isActive, isPending }) =>
								isActive ? `${navLink + 'active'}` : isPending ? `${navLink + 'pending'}` : `${navLink}`
							}>
								Interest Rate
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={'fx-rate'} className={({ isActive, isPending }) =>
								isActive ? `${navLink + 'active'}` : isPending ? `${navLink + 'pending'}` : `${navLink}`
							}>
								FX Rate
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	)
}
