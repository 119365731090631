import { useApi } from "../../context/appContext";
import Chart from "./Chart";
import TableData from "./TableData";

export function InterestRate() {
	const { interestRate } = useApi();

	return (
		<div className="container">
			<section className="bg-dark pt-8 text-lg-start">
				
					<div className="mt-6 text-lg-start text-center">
						<h2>Interest rates: 2W repo rate, Rate in %, Percentages, Daily</h2>
					</div>

					<div className="d-flex flex-column mt-5 text-light">
						<div className="d-flex flex-lg-row flex-column">
							<div className={'col-lg-9 col-12'}>
								{interestRate && (
									<Chart data={interestRate} width={1100} height={500} />
								)}
								<div className="d-flex flex-column small my-3">
									<code>* Source: https://cnb.cz/arad/</code>
								</div>
							</div>
							<div className={'col-lg-3 col-12 mx-lg-5 my-lg-0 my-4'}>
								<h4 className="border-bottom pb-3 text-lg-start text-center">Latest data</h4>

								<div>
									{interestRate && (
										<div className="d-flex flex-row justify-content-between py-3 fw-bold">
											<span>Period</span>
											<span>Rate</span>
										</div>
									)}
									{interestRate && <TableData data={interestRate.slice(0, 6)} />}
								</div>
							</div>

						</div>
					</div>
					<div className="d-flex text-light">
						<div className="col-12">
							<span>
								The interest rate has a rather serious impact on the economy of the country. Controlling the interest rate allows the bank to maintain moderate inflation and ensure price stability. That is why it is important to pay attention to this indicator when investing.
							</span>
							<br />
							<span>
								The graph shows the change in interest rates over several years. This information will allow you to analyze the impact of the rate on the Czech economy and the country's investment climate.

								All data is obtained from official sources and is updated as the data is updated.
							</span>
						</div>
					</div>
				
			</section>
		</div>
	)
}