import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	scales: {
		x: {
			grid: {
				color: 'rgba(255, 255, 255, 0.1)'
			},
			ticks: {
				color: 'rgba(255, 255, 255)'
			}
		},
		y: {
			grid: {
				color: 'rgba(255, 255, 255, 0.1)'
			}
		}
	},
	plugins: {
		legend: {
			position: 'top'
		},
		title: {
			// display: true,
			// text: 'Unemployment Rate in Czech Republic'
		}
	}
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


class UnemploymentChart extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoaded: false,
			error: null,
		}
	}

	componentDidMount() {

		this.setState({
			isLoaded: true,
			data: this.props.unemployment,
		})

		// fetch('/unemployment.json').then(res => res.json())
		// .then((result) => {

		// 	this.setState({
		// 		isLoaded: true,
		// 		data: result.reverse()
		// 	})
		// }, 
		// (error) => {
		// 	this.setState({
		// 		isLoaded: true,
		// 		error
		// 	})
		// })
	}

	getLabels() {
		return this.state.data.map(data => (
			data.period.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2 $1")
		))
	}

	getValues(type) {
		return this.state.data.map(data => (
			data.value.toFixed(2)
		))
	}

	render() {

		const { error, isLoaded } = this.state;

		if (error) {
			return <div>Error: {error.message}</div>
		} else if (!isLoaded) {
			return <div>Loading...</div>
		} else {

			let labels = this.getLabels();

			let qoqData = {
				labels,
				datasets: [
					{
						label: 'Unemployment rate, %',
						data: this.getValues(),
						backgroundColor: 'lightblue',
					},
				],
			};

			return (
				<>
					{/* <h2 className="text-light">Unemployment Rate in Czech Republic</h2> */}
					<Bar options={options} data={qoqData} />
				</>
			)
		}
	}
}

export default UnemploymentChart;