import * as d3 from "d3"
import { splitByColumn } from '../../lib/Legends'

export default function StackedBars(data, {
	x = (d, i) => i, // given d in data, returns the (ordinal) x-value
	y = d => d, // given d in data, returns the (quantitative) y-value
	z = () => 1, // given d in data, returns the (categorical) z-value
	title, // given d in data, returns the title text
	marginTop = 30, // top margin, in pixels
	marginRight = 0, // right margin, in pixels
	marginBottom = 30, // bottom margin, in pixels
	marginLeft = 40, // left margin, in pixels
	width = 1000, // outer width, in pixels
	height = 800, // outer height, in pixels
	xDomain, // array of x-values
	xRange = [marginLeft, width - marginRight], // [left, right]
	xPadding = 0.2, // amount of x-range to reserve to separate bars
	yType = d3.scaleLinear, // type of y-scale
	yDomain, // [ymin, ymax]
	yRange = [height - marginBottom, marginTop], // [bottom, top]
	zDomain, // array of z-values
	offset = d3.stackOffsetDiverging, // stack offset method
	order = d3.stackOrderNone, // stack order method
	yFormat, // a format specifier string for the y-axis
	yLabel, // a label for the y-axis
	colors = d3.schemePaired, // array of colors,
	svg,
	blockHeight = height
} = {}) {
	// Compute values.
	const X = d3.map(data, x);
	const Y = d3.map(data, y);
	const Z = d3.map(data, z);

	// Compute default x- and z-domains, and unique them.
	if (xDomain === undefined) xDomain = X;
	if (zDomain === undefined) zDomain = Z;
	xDomain = new d3.InternSet(xDomain);
	zDomain = new d3.InternSet(zDomain);

	// Omit any data not present in the x- and z-domains.
	const I = d3.range(X.length).filter(i => xDomain.has(X[i]) && zDomain.has(Z[i]));

	// Compute a nested array of series where each series is [[y1, y2], [y1, y2],
	// [y1, y2], …] representing the y-extent of each stacked rect. In addition,
	// each tuple has an i (index) property so that we can refer back to the
	// original data point (data[i]). This code assumes that there is only one
	// data point for a given unique x- and z-value.
	const series = d3.stack()
		.keys(zDomain)
		.value(([x, I], z) => Y[I.get(z)])
		.order(order)
		.offset(offset)
		(d3.rollup(I, ([i]) => i, i => X[i], i => Z[i]))
		.map(s => s.map(d => Object.assign(d, { i: d.data[1].get(s.key) })));

	
	// Compute the default y-domain. Note: diverging stacks can be negative.
	if (yDomain === undefined) yDomain = d3.extent(series.flat(2)).map((item, key) => {
		return key === 1 ? Math.floor(item + 10) : item;
	});

	// Construct scales, axes, and formats.
	const xScale = d3.scaleBand(xDomain, xRange)
		.rangeRound([0, width - 35])
		.paddingInner(xPadding);

	const yScale = yType(yDomain, yRange);
	const color = d3.scaleOrdinal(zDomain, colors);

	const xAxis = g => g
		// .axisBottom(xScale)
		// .attr('transform', `translate(0, ${height - 64})`)
		.call(
			d3.axisBottom(xScale)
				.tickSizeOuter(0)
		);
	const yAxis = d3.axisRight(yScale).ticks(blockHeight / 60, yFormat);

	// Compute titles.
	if (title === undefined) {
		const formatValue = yScale.tickFormat(100, yFormat);
		title = i => `${X[i]}\n${Z[i]}\n${formatValue(Y[i])}`;
	} else {
		const O = d3.map(data, d => d);
		const T = title;
		title = i => T(O[i], i, data);
	}

	svg.append("g")
		.attr('transform', `translate(${width - marginRight}, 0)`)
		.call(yAxis)
		.call(g => g.select(".domain").remove())
		.call(g => g.selectAll(".tick line").clone()
			.attr("x2", -width + marginRight)
			.attr("stroke-opacity", 0.1))
		.call(g => g.append("text")
			.attr("x", 10)
			.attr("y", 10)
			.attr("fill", "currentColor")
			.attr("text-anchor", "start")
			.text(yLabel));

	const bar = svg.append("g")
		.selectAll("g")
		.data(series)
		.join("g")
		.attr("fill", ([{ i }]) => color(Z[i]))
		.selectAll("rect")
		.data(d => d)
		.join("rect")
		.attr("x", ({ i }) => xScale(X[i]))
		.attr("y", ([y1, y2]) => Math.min(yScale(y1), yScale(y2)))
		.attr("height", ([y1, y2]) => Math.abs(yScale(y1) - yScale(y2)))
		.attr("width", xScale.bandwidth());

	if (title) bar.append("title")
		.text(({ i }) => title(i));

	svg.append("g")
		.attr("transform", `translate(0,${yScale(0)})`)
		.call(xAxis)
		.selectAll('text')
		.each(function(d, i) {
			let odd = i % 2 === 1;

			if(odd) {
				d3.select(this)
					.remove()
			}
		})
		.attr("transform", "translate(-10,50)rotate(-45)")
		;


	// svg
	// .attr("align", "left")
	// .attr("viewBox", [0, 0, width, blockHeight]);

	let legendHolder = svg.append('g')
		.attr('transform', `translate(0, ${height})`)

	legendHolder
		.selectAll("mydots")
		.data(zDomain)
		.enter()
		.append("rect")
		.attr("x", 0)
		.attr("y", function (d, i) { return 50 }) // 100 is where the first dot appears. 25 is the distance between dots
		.attr('width', 15)
		.attr('height', 15)
		.style("fill", function (d) { return color(d) })
		.attr("class", "legend")
		// .attr("transform", function (d, i) { return "translate(" + i * 150 + ", 0)"; })
		.attr('transform', (d, i) => splitByColumn(d, i, 2))
		;

	legendHolder
		.selectAll("mylabels")
		.data(zDomain)
		.enter()
		.append("text")
		.attr("x", 20)
		.attr("y", function (d, i) { return 60 })
		// .style("fill", function (d) { return color(d) })
		.text(function (d) { return d })
		.attr('transform', (d, i) => splitByColumn(d, i, 2))
		.attr("text-anchor", "left")
		.style("alignment-baseline", "middle")
		.attr("font-family", "sans-serif")
		.attr("font-size", 13)
		.attr('fill', '#fff')

	return Object.assign(svg.node(), { scales: { color } });
}
