export default function TableData(props) {

	const { data } = props;

	return data && Object.keys(data).map(key => (
		<div key={key} className={"d-flex flex-row justify-content-between border-bottom py-1 align-items-center " + (key === 5 ? '' : '')}>
			<div className={'d-flex'}>
				<span className={'title'}>{data[key].name},</span>
				<span className={'ticker text-secondary'}>{key}</span>
			</div>
			<span className={'weight'}>{data[key].weight}%</span>
		</div>
	))
}