import { useApi } from "../../context/appContext";
import PieChart from "./PieChart";
import TableData from './TableData'

export function Portfolio() {
	const { portfolio } = useApi();

	console.log(portfolio)

	return (
		<div className="container">
			<section className="bg-dark pt-8 text-lg-start">
				<div className="mt-6 text-lg-start text-center">
					<h2>Portfolio tracker</h2>
				</div>
				<div className="d-flex flex-column mt-5 text-light">
					<div className="d-flex flex-lg-row flex-column justify-content-around">
						<div className={"col-lg-6 col-12"}>
							{/* {portfolio.byDate ? 
								<LineChart data={portfolio.byDate} /> : <></>
							} */}
							
							{portfolio.byDate ? 
								<PieChart data={portfolio.byName} /> : <></>
							}
						</div>
						<div className={'col-lg-4 col-12 mx-lg-5 my-lg-0 my-4 holdings'}>
							<h4 className="pb-3 text-lg-start text-center border-bottom">Portfolio</h4>

							<div>
							{portfolio && 
								<TableData data={portfolio.byName} />
							}
							</div>
						</div>
					</div>
				</div>
				
			</section>
		</div>
	);
}
