import { Link, NavLink } from "react-router-dom";

export default function Footer() {
	let date = new Date();

	return (
		<section className="py-4 bg-dark text-white border-top border-secondary">
			<div className="container">
				<div className="d-flex align-items-center flex-md-row flex-column justify-content-between">
					<div className="d-flex flex-row justify-content-between col-lg-5 col-md-8 col-12">
						<div className="col-6">

							<NavLink to={'/'} className={'nav-link px-2 py-1 py-1'}>
								<i className="me-2 fa-sharp fa-light fa-house" style={{ color: 'orange' }}></i>
								<span>Home</span>
							</NavLink>

							<NavLink to={'cpi'} className={'nav-link px-2 py-1'}>
								<i className="me-2 fa-sharp fa-light fa-hand-holding-dollar" style={{ color: 'orange' }}></i>
								<span>CPI</span>
							</NavLink>
							<NavLink to={'gdp'} className={'nav-link px-2 py-1'}>
								<i className="me-2 fa-sharp fa-light fa-wave-pulse" style={{ color: 'orange' }}></i>
								<span>GDP</span>
							</NavLink>
						</div>
						<div className="col-6">
							<NavLink to={'unemployment'} className={'nav-link px-2 py-1'}>
								<i className="me-2 fa-sharp fa-light fa-people-group" style={{ color: 'orange' }}></i>
								<span>Unemployment rate</span>
							</NavLink>
							<NavLink to={'interest-rate'} className={'nav-link px-2 py-1'}>
								<i className="me-2 fa-sharp fa-light fa-badge-percent" style={{ color: 'orange' }}></i>
								<span>Interest Rate</span>
							</NavLink>
							<NavLink to={'fx-rate'} className={'nav-link px-2 py-1'}>
								<i className="me-2 fa-sharp fa-light fa-money-bill-transfer" style={{ color: 'orange' }}></i>
								<span>FX Rate</span>
							</NavLink>
						</div>
					</div>
					<div className="my-lg-0 my-4">
						{/* <a href="/">
							<img className="img-fluid mt-4 mb-4" src="/logo_short_2.svg" alt="Footer logo" width={170} />
						</a> */}
						<Link className="text-lg-start text-center me-3 w-25 w-lg-75" to="https://apps.apple.com/cz/app/czech-stats/id1669200005" target="_blank">
							<img src="/images/czechstats/apple-store_white.svg" alt="Mobile app" />
						</Link>
					</div>

				</div>

				<div className="d-flex mt-3 flex-row justify-content-lg-between justify-content-center px-2">

					<div className="d-flex flex-row align-items-center">

						<p className="mb-0 me-3 text-muted" style={{ fontSize: 12 }}>© {date.getFullYear()} CzechStats</p>
						<NavLink to={'privacy'} className={'nav-link text-muted me-3'} style={{ fontSize: 12 }}>
							Privacy Policy
						</NavLink>
						<NavLink to={'https://linkedin.com/in/ishemgulov'} className={'nav-link text-muted'} style={{ fontSize: 12 }} target="_blank">
							<i className="me-2 fa-brands fa-linkedin text-success" style={{ color: 'primary' }}></i>
							<span>Author</span>
						</NavLink>
					</div>
				</div>

			</div>

			{/* <div className="container">
				<div className="border-top my-3 border-secondary text-end py-3">
					<Link className="text-lg-start text-center me-3 w-25 w-lg-75" to="https://apps.apple.com/cz/app/czech-stats/id1669200005" target="_blank">
						<img src="/images/czechstats/apple-store_white.svg" alt="Mobile app" />
					</Link>
				</div>
			</div> */}
		</section>
	)
}