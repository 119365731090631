import * as d3 from "d3";
import { useEffect, useRef } from "react";
import StackedBars from "./StackedBars";

function CategoriesCpi(props) {

	const { width = 500, height = 600, categories } = props;

	const ref = useRef();
	const divRef = useRef();

	useEffect(() => {
		const svg = d3.select(ref.current)
			.attr('viewBox', [0, 0, width, 800]);

		// d3.json("/cpi_categories.json").then(function (categories) {

			let data = Object.values(categories).slice(-39);

			let columns = Object.keys(Object.values(data).shift()).filter(item => !["Total", "reportDate"].includes(item))

			let cpi = columns.flatMap(
				column => Object.values(data).map(
					d => ({ date: d.reportDate, column, value: d[column] })
				)
			)

			StackedBars(cpi, {
				x: d => d.date,
				y: d => d.value - 100,
				z: d => d.column,
				yLabel: '%',
				// xDomain: d3.groupSort(cpi, D => d3.sum(D, d => -d.value), d => d.date),
				zDomain: columns,
				colors: d3.interpolateRdYlGn[columns.length],
				width: width,
				height: height,
				blockHeight: 800,
				svg,
				marginBottom: 40,
				marginLeft: 10,
				marginRight: 30
			})
		// })
	}, [categories, height, width])

	return (
		<div style={{ position: 'relative', minHeight: '1.5rem' }} ref={divRef}>
			<svg ref={ref} />
		</div>
	)
}

export default CategoriesCpi;