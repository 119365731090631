import * as d3 from "d3";
import { useEffect, useRef } from "react";

function CommonCpi(props) {

	const { width = 500, height = 600 } = props;
	const margin = ({ top: 20, right: 30, bottom: 40, left: 5 })

	const { cpi } = props;

	const ref = useRef();
	const divRef = useRef();

	useEffect(() => {
		const svg = d3.select(ref.current)
			.attr('viewBox', [0, 0, width, height]);

		const data = cpi.slice(0, 40).reverse();

		const x = d3.scaleBand()
			.domain(data.map(d => d.date))
			.rangeRound([0, width - margin.right])
			.padding(0.2)

		// Right Axis, CPI
		const y2 = d3.scaleLinear()
			.domain([0, d3.max(data, d => d.cpi)])
			.rangeRound([height - margin.bottom, margin.top]);

		const xAxis = g => g
			.attr('transform', `translate(0, ${height - margin.bottom})`)
			.call(
				d3.axisBottom(x)
					// .tickValues(d3.ticks(...d3.extent(x.domain()), width / 40).filter(v => x(v) !== undefined))
					.tickSizeOuter(0)
			);


		const y2Axis = g => g
			.attr('transform', `translate(${width - margin.right}, 0)`)
			.call(d3.axisRight(y2))
			// .call(g => g.select('.text').remove())
			.call(g => g.selectAll(".tick line").clone()
				.attr("x2", -width + margin.right)
				.attr("stroke-opacity", 0.1)
			)
			.call(g => g.select('.domain').remove())
			.call(
				g => g.append('text')
					.attr('x', -width / 2)
					.attr('y', 10)
					.attr('fill', 'red')
					.attr('text-anchor', 'end')
				// .text('Inflation index')
			);

		svg.append('g')
			.call(y2Axis);



		// CPI bars
		svg
			.append('g')
			.attr('fill', 'steelblue')
			.attr('fill-opacity', 1)
			.selectAll('rect')
			.data(data)
			.join('rect')
			.attr('x', d => x(d.date))
			.attr('width', x.bandwidth() / 2)
			.attr('y', d => y2(d.cpi))
			.attr('height', d => y2(0) - y2(d.cpi))
			;

		svg
			.append('g')
			.attr('fill', 'orange')
			.attr('fill-opacity', 1)
			.selectAll('rect')
			.data(data)
			.join('rect')
			.attr('x', d => x(d.date) + x.bandwidth() / 2)
			// .attr('x', d => x(d.date))
			.attr('width', x.bandwidth() / 2)
			.attr('y', d => y2(d.core))
			.attr('height', d => y2(0) - y2(d.core))
			;

		svg.append('g')
			.attr('fill', 'none')
			.attr('pointer-events', 'all')
			.selectAll('rect')
			.data(data)
			.join('rect')
			.attr('x', d => x(d.date))
			.attr('width', x.bandwidth())
			.attr('y', 0)
			.attr('height', height)
			.append('title')
			.text(d => `${d.date}
				Core: ${d.core}
				CPI: ${d.cpi}
				`);

		// Horizontal & vertical lines

		svg
			.append('g')
			.attr('fill', 'white')
			.attr('fill-opacity', 0.1)
			.selectAll('rect')
			.data(data)
			.join('rect')
			.attr('x', d => x(d.date) + (x.bandwidth() / 2))
			.attr('width', 1)
			// .attr('y', d => )
			.attr('height', height - margin.bottom)
			;


		svg.append('g')
			.call(xAxis)
			.selectAll('text')
			.attr("transform", "translate(-10,10)rotate(-45)");

		const barColors = [
			'steelblue',
			'orange'
		];


		let legendHolder = svg.append('g')
			.attr('transform', `translate(${margin.right}, ${margin.left})`)

		legendHolder
			.selectAll("mydots")
			.data(['CPI', 'Core'])
			.enter()
			.append("rect")
			.attr("x", 10)
			.attr("y", function (d, i) { return i * 20 + 50 }) // 100 is where the first dot appears. 25 is the distance between dots
			.attr('width', 15)
			.attr('height', 15)
			.style("fill", function (d, i) { return barColors[i] })
			.attr("class", "legend")
		// .attr("transform", function (d, i) { return "translate(" + i * 150 + ", 0)"; })

		legendHolder
			.selectAll("mylabels")
			.data(['CPI', 'Core CPI'])
			.enter()
			.append("text")
			.attr("x", 35)
			.attr("y", function (d, i) { return i * 20 + 60 })
			.style("fill", function (d, i) { return barColors[i] })
			.text(function (d) { return d })
			.attr("text-anchor", "left")
			.style("alignment-baseline", "middle")
			.attr("font-family", "sans-serif")
			.attr("font-size", 13)
			.attr('fill', '#fff')

		// })

	}, [cpi, height, margin.bottom, margin.left, margin.right, margin.top, width])

	return (
		<div style={{ position: 'relative', minHeight: '1.5rem' }} ref={divRef}>
			<svg ref={ref} />
		</div>
	)
}

export default CommonCpi;