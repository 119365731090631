import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import GdpTableData from '../../pages/Gdp/GdpTableData';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	scales: {
		x: {
			grid: {
				color: 'rgba(255, 255, 255, 0.1)'
			},
			ticks: {
				color: 'rgba(255, 255, 255)'
			}
		},
		y: {
			grid: {
				color: 'rgba(255, 255, 255, 0.1)'
			}
		}
	},
	plugins: {
		legend: {
			position: 'top',
			// display: false,
			labels: {
				filter: function (item, data) {
					return item.datasetIndex > 0 ? data : null;
				}
			}
		},
		title: {
			// display: true,
			text: 'GDP Q-o-Q chart'
		}
	}
};


class GdpChart extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			qoq: [],
			yoy: [],
			isLoaded: false,
			error: null,
			data: []
		}
	}

	componentDidMount() {

		this.setState({
			isLoaded: true,
			qoq: this.props.gdp.qoq,
			yoy: this.props.gdp.yoy
		})
	}

	getLabels() {
		return this.state.qoq.map(data => (
			data.period.replace(/(\d{4})([A-Z]\d)/, "$1 $2")
		))
	}

	getValues(type) {
		return (type === 'q') ?
			this.state.qoq.map(data => (
				data.value
			)) :
			this.state.yoy.map(data => (
				data.value
			))

	}

	render() {

		const { error, isLoaded } = this.state;

		if (error) {
			return <div>Error: {error.message}</div>
		} else if (!isLoaded) {
			return <div>Loading...</div>
		} else {

			let labels = this.getLabels();

			let qoqData = {
				labels,
				datasets: [
					{
						type: 'line',
						data: this.getValues('q').map(item => (item !== 0 ? null : item)),
						label: '',
						borderColor: 'lightblue',
						pointBackgroundColor: 'lightblue',
						title: '',
						borderWidth: 3,
						pointRaduis: 1,
						pointHoverRaduis: 4,
						showLine: false,
						pointStyle: 'rectRot'
					},
					{
						label: 'GDP, Q-o-Q, %',
						// label: 'GDP, Constant Prices, Previous Period = 100, Seasonally Adjusted',
						data: this.getValues('q'),
						// backgroundColor: 'rgba(255, 255, 255, 0.9)',
						backgroundColor: 'lightblue',
						pointStyle: 'triangle'
					},
				],
			};

			let yoyData = {
				labels,
				datasets: [
					{
						label: 'GDP Y-o-Y, %',
						// label: "GDP, Constant Prices, Y-o-Y index, Seasonally Adjusted",
						data: this.getValues('y'),
						// backgroundColor: 'rgba(255, 255, 255, 0.9)',
						backgroundColor: 'lightblue'
					},
				],
			};

			return (
				<>
					<div className="d-flex flex-column mt-5 text-light">
						<div className="d-flex flex-lg-row flex-column">
							<div className={'col-lg-9 col-12'}>
								<Bar options={options} data={qoqData} style={{ marginBottom: 20 }} />
								<div className="d-flex flex-column py-4 small">
									<code>* Source: https://cnb.cz/arad/</code>
									<code>* GDP, Constant Prices, Previous Period = 100, Seasonally Adjusted</code>
								</div>
							</div>

							<div className={'col-lg-3 col-12 mx-lg-5 my-lg-0 my-4'}>
								<h4 className="border-bottom pb-3 text-lg-start text-center">Latest data</h4>

								<div>
									{this.state.qoq && (
										<div className="d-flex flex-row justify-content-between py-3 fw-bold">
											<span>Period</span>
											<span>GDP</span>
										</div>
									)}
									{this.state.qoq && <GdpTableData data={this.state.qoq.slice(-6).reverse()} />}
								</div>
							</div>
						</div>

						<div className="d-flex flex-lg-row flex-column">
							<div className={'col-lg-9 col-12'}>
								<Bar options={options} data={yoyData} style={{ marginTop: 20 }} />
								<div className="d-flex flex-column small my-3">
									<code>* Source: https://cnb.cz/arad/</code>
									<code>* GDP, Constant Prices, Y-o-Y index, Seasonally Adjusted</code>
								</div>
							</div>
							<div className={'col-lg-3 col-12 mx-lg-5 my-lg-0 my-4'}>
								<h4 className="border-bottom pb-3 text-lg-start text-center">Latest data</h4>

								<div>
									{this.state.yoy && (
										<div className="d-flex flex-row justify-content-between py-3 fw-bold">
											<span>Period</span>
											<span>GDP</span>
										</div>
									)}
									{this.state.yoy && <GdpTableData data={this.state.yoy.slice(-6).reverse()} />}
								</div>
							</div>
						</div>
					</div>
				</>
			)
		}
	}
}

export default GdpChart;