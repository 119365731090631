import {
	CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
	Tooltip
} from 'chart.js';
import React from 'react';
import { LineWave } from 'react-loader-spinner';
import { useApi } from '../../context/appContext';
import FxRateChart from './Chart';
import TableData from './TableData';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export function FxRate() {

	const { fxRate } = useApi()

	return (
		<div className="container">
			<section className="bg-dark pt-8 text-lg-start">
				<div className="mt-6 text-lg-start text-center">
					<h2>Exchange rate, EUR & USD</h2>
				</div>

				<div className="d-flex flex-column mt-5 text-light">
					<div className="d-flex flex-lg-row flex-column">
						<div className={'col-lg-9 col-12'}>
							{fxRate ?
								<FxRateChart data={fxRate} /> :
								<div>
									<LineWave
										wrapperClass='justify-content-center'
										color="orange"
										middleLineColor='red'
										width={150}
										height={150}
									/>
								</div>}
						</div>
						<div className={'col-lg-3 col-12 mx-lg-5 my-lg-0 my-4'}>
							<h4 className="pb-3 text-lg-start text-center border-bottom">Latest data</h4>

							<div>
								{fxRate && (
									<div className="d-flex flex-row justify-content-between py-3 fw-bold">
										<span>Period</span>
										<span>EUR</span>
										<span>USD</span>
									</div>
								)}
								{fxRate && <TableData data={fxRate.slice(0, 10)} />}
							</div>
						</div>
					</div>
					<div className="d-flex flex-column py-4 small">
						<code>* Source: https://cnb.cz/arad/</code>
					</div>
				</div>

				<div className="d-flex text-light">
					<div className="col-12">
						<span>
							The graph shows the exchange rates of the euro and the dollar against the krone over the past 10 years. The euro and the dollar have a certain impact on the economy and trade relations between countries. Exchange rates have an impact on competitiveness and trading volumes.
							The data for the last 10 years will give a better understanding of the behavior of these currencies.
						</span>
					</div>
				</div>
			</section>
		</div>
	)
}
