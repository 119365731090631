import { createBrowserRouter } from "react-router-dom";

import Layout from "../components/Layout";
import Sup from "../components/sup";
import Cpi from "../pages/Cpi";
import { FxRate } from "../pages/Fx";
import GDP from '../pages/Gdp';
import Home from '../pages/Home';
import { InterestRate } from "../pages/InterestRate";
import PrivacyPolicy from "../pages/Static/Privacy";
import Unemployment from '../pages/Unemployment';
import { Portfolio } from "../pages/Portfolio";

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Home />
			},
			{
				path: 'gdp',
				element: <GDP />,
			},
			{
				path: 'unemployment',
				element: <Unemployment />
			},
			{
				path: 'interest-rate',
				element: <InterestRate />
			},
			{
				path: 'sup',
				element: <Sup />
			},
			{
				path: 'privacy',
				element: <PrivacyPolicy />
			},
			{
				path: 'fx-rate',
				element: <FxRate />
			},
			{
				path: 'cpi',
				element: <Cpi />
			},
			{
				path: 'portfolio',
				element: <Portfolio />
			}
		]
	},

]);

export default router;