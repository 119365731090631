import UnemploymentChart from '../../charts/unemployment';
import { useApi } from '../../context/appContext';
import TableData from './TableData';

export default function UnemploymentRate() {

	const { unemployment } = useApi();

	return (
		<div className="container">
			<section className="bg-dark pt-8 text-lg-start">

				<div className="mt-6 text-lg-start text-center">
					<h2>Unemployment Rate in Czech Republic</h2>
				</div>

				<div className="d-flex flex-column mt-5 text-light">
					<div className="d-flex flex-lg-row flex-column">
						<div className={'col-lg-9 col-12'}>
							{unemployment && (
								<UnemploymentChart unemployment={[...unemployment].reverse()} />
							)}
							<div className="d-flex flex-column py-4 small">
								<code>* Source: https://cnb.cz/arad/</code>
							</div>
						</div>
						<div className={'col-lg-3 col-12 mx-lg-5 my-lg-0 my-4'}>
							<h4 className="border-bottom pb-3 text-lg-start text-center">Latest data</h4>

							<div>
								{unemployment && (
									<div className="d-flex flex-row justify-content-between py-3 fw-bold">
										<span>Period</span>
										<span>Rate</span>
									</div>
								)}
								{unemployment && <TableData data={unemployment.slice(0, 6)} />}
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex text-light">
					<div className="col-12">
						<span>
							The unemployment rate is another important macro-indicator that affects the country's economic climate.

							The graph displays the figures for the last few years for a better understanding of the overall picture of unemployment in the Czech Republic. With unemployment figures at hand, you can analyze the data against unemployment data to see the impact on the economic climate.
							When you hover over a column, you will see more detailed information.

							All data is obtained from official sources and is updated as the data is updated.

						</span>
					</div>
				</div>
			</section>
		</div>
	)
}