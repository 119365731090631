import { Link } from "react-router-dom"

export default function LatestDataItem(props) {

	const { data, title, subtitle, suffix, linkTo } = props

	return (
		<div className='col-md-3'>
			<div className="d-flex flex-column">
				<div
					className="
						bg-dark
						text-white
						my-2 
						rounded-1 											
						d-flex 
						justify-content-between 
						px-3 
						py-3 
						align-items-center"
				>
					<div className="d-flex flex-column">
						<span className="small fw-light">{title}</span>
						<span className="py-2 fs-3">{data}{suffix}</span>
						<span className="fw-medium small">{subtitle}</span>
					</div>
					<div>
						<Link to={linkTo} style={{ color: "orange" }}>
							<i className="fa-sharp fa-solid fa-circle-arrow-right fa-2x"></i>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}