import {
	ArcElement,
	Chart as ChartJS,
} from "chart.js";
import React, { useMemo } from "react";
import {Doughnut } from "react-chartjs-2";
import * as d3 from "d3";

ChartJS.register(
	ArcElement,

);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "top",
			labels: {
				color: "white",
			},
		},
		title: {
			color: "white",
		},
	},
	animation: false,
};

export default function PieChart(props) {
	const transactions = props.data;

	const data = useMemo(() => {
		const labels = Object.keys(transactions);
		const values = Object.values(transactions);
		
		const obj = {
			labels: labels,
			datasets: [
				{
					label: "Weight, %",
					data: values.map(item => item.weight),
					backgroundColor: d3.schemePaired,
					hoverOffset: 4,
				},
			],
		};

		return obj;
	}, [transactions]);

	return data && <Doughnut options={options} data={data} />;
}
