import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from './Footer';
import Header from "./Header";

function Layout(props) {

	const [scroll, setScroll] = useState(false);
	const headerRef = useRef(null);

	const handleScroll = (e) => {
		setScroll(window.scrollY > 50);
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [])

	return (
		<div className="">
			<main className="main" id="top" onScroll={handleScroll} ref={headerRef}>
				<Header scrolled={scroll} />
				<div className="">
					<Outlet />
				</div>
			</main>
			<Footer />
		</div>
	)
}

export default Layout;