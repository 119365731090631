
export function splitByColumn(d, i, columns = 3) {
	var h = 20;  // legend entry height
	var w = 500	; // legend entry width (so we can position the next column) 
	var tx = 10; // tx/ty are essentially margin values
	var ty = 10;
	var x = i % columns * w + tx;
	var y = Math.floor(i / columns) * h + ty;
	return "translate(" + x + "," + y + ")";
}
