import {
	CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
	Tooltip
} from 'chart.js';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);


export const options = {
	responsive: true,
	scales: {
		x: {
			grid: {
				color: 'rgba(255, 255, 255, 0.1)'
			},
			ticks: {
				color: 'rgba(255, 255, 255)'
			}
		},
		y: {
			grid: {
				color: 'rgba(255, 255, 255, 0.1)'
			},
			type: 'linear',
			display: true,
			position: 'left',
			ticks: {
				color: 'rgb(255, 99, 132)'
			}
		},
		y1: {
			type: 'linear',
			display: true,
			position: 'right',
			grid: {
				drawOnChartArea: false,
			},
			ticks: {
				color: 'rgb(53, 162, 235)'
			}
		}
	},
	plugins: {
		legend: {
			position: 'top',
			labels: {
				color: 'white',
			}
		},
		title: {
			color: 'white',
		}
	},
	animation: false,
};


export default function FxRateChart(props) {

	const rates = props.data;

	// const [data, setData] = useState()

	const data = useMemo(() => {
		const reversed = rates.toReversed();
		const labels = reversed.map(item => item.date);

		const obj = {
			labels,
			datasets: [
				{
					label: 'EUR (left)',
					data: reversed.map(item => item.value.eur),
					borderColor: 'rgb(255, 99, 132)',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
					yAxisID: 'y',
					spanGaps: true
					// parsing: false,
					// normalized: true
				},
				{
					label: 'USD (right)',
					data: reversed.map(item => item.value.usd),
					borderColor: 'rgb(53, 162, 235)',
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
					yAxisID: 'y1',
					spanGaps: true
					// parsing: false,
				},
			],
		}

		return obj;
		
	}, [rates])

	
	// useEffect(() => {
	// 	const reversed = rates.reverse();
	// 	const labels = reversed.map(item => item.date);

	// 	console.log(labels[0]);

	// 	setData(
	// 		{
	// 			labels,
	// 			datasets: [
	// 				{
	// 					label: 'EUR (left)',
	// 					data: reversed.map(item => item.value.eur),
	// 					borderColor: 'rgb(255, 99, 132)',
	// 					backgroundColor: 'rgba(255, 99, 132, 0.5)',
	// 					yAxisID: 'y',
	// 					spanGaps: true
	// 					// parsing: false,
	// 					// normalized: true
	// 				},
	// 				{
	// 					label: 'USD (right)',
	// 					data: reversed.map(item => item.value.usd),
	// 					borderColor: 'rgb(53, 162, 235)',
	// 					backgroundColor: 'rgba(53, 162, 235, 0.5)',
	// 					yAxisID: 'y1',
	// 					spanGaps: true
	// 					// parsing: false,
	// 				},
	// 			],
	// 		}
	// 	)
	// }, [])

	return (
		data && (<Line options={options} data={data} />)
	)
}