import GdpChart from "../../charts/gdp";
import { useApi } from '../../context/appContext';

export default function Gdp() {

	const { gdp } = useApi();

	return (
		<div className="container">

			<section className="bg-dark pt-8 text-lg-start">

				<div className="mt-6 text-lg-start text-center">
					<h2>Gross domestic product</h2>
				</div>

				{
					gdp && (
						<GdpChart gdp={gdp} />
					)
				}
				<div className="d-flex text-light">
					<div className="col-12">
						<span>
							GDP is a general indicator of economic activity and is a key indicator of the health of the Czech economy. GDP dynamics in the Czech Republic show the growth rate of the economy as a whole, despite fluctuations over the past couple of years.
						</span>
						<br />
						<span>
							In general, the Czech Republic has a relatively favorable business climate, which contributes to the inflow of investors, and thus the GDP figure can grow. Furthermore, the Czech Republic has a diversified economy with a wide range of sectors, which also supports GDP growth.

							All data are derived from official sources and are updated as the data are updated.
						</span>
					</div>
				</div>
			</section>
		</div>
	)
}