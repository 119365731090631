import CommonCpi from '../../charts/cpi/CommonCpi';
import CategoriesCpi from '../../charts/cpi/CategoriesCpi';
import { useApi } from "../../context/appContext";
import LatestCategoriesData from './LatestCategoriesData';
import LatestCommonData from './LatestCommonData';

export default function Cpi() {

	const { cpi, categoriesCpi } = useApi();

	const isMobile = false; //window.screen.availWidth < 768;
	const isLandscape = window.screen.orientation.type === 'landscape-primary'


	return (
		<div className="container">
			<section className="bg-dark pt-8 text-lg-start">
				<div className="mt-6 text-lg-start text-center">
					<h2>Consumer Price Index</h2>
				</div>

				<div className="d-flex flex-column mt-5 text-light">

					<div className="d-flex flex-lg-row flex-column">
						<div className={'col-lg-9 col-12'}>
							{cpi && (<CommonCpi width={1000} height={500} cpi={cpi} />)}
						</div>

						<div className={'col-lg-3 col-12 mx-lg-5 my-lg-0 my-4'}>
							<h4 className="pb-3 text-lg-start text-center">Latest data (last 6 months)</h4>

							<div>
								{cpi && (
									<div className="d-flex flex-row justify-content-between py-3 fw-bold">
										<span>Period</span>
										<span>CPI / Core CPI</span>
									</div>
								)}
								{cpi && <LatestCommonData data={cpi.slice(0, 6)} />}
							</div>
						</div>
					</div>

					<div className="d-flex flex-column py-4 small">
						<code>* Source: https://cnb.cz/arad/</code>
						<code>* CPI = Consumer Price Index, %</code>
						<code>* Core CPI = (CPI - Food prices - Energy/Fuel Prices), %</code>
					</div>

				</div>
				<div className="d-flex flex-lg-row flex-column mt-1 text-light">
					<div className="col-lg-9 col-12">
						{categoriesCpi && (
							<CategoriesCpi width={1000} height={600} categories={categoriesCpi} />
						)}
					</div>
					<div className="col-lg-3 col-sm-8 mx-auto mx-lg-5 my-lg-0 my-3">
						<h4 className="pb-3">Latest data (previous month)</h4>

						{categoriesCpi && (
							<div className={"fw-bold d-flex flex-row justify-content-between px-3 py-2 align-items-center border border-secondary"}>
								<span>Category</span>
								<span>Value</span>
							</div>
						)}
						{categoriesCpi && <LatestCategoriesData data={categoriesCpi} />}
					</div>
				</div>

				<div className="d-flex mt-1 text-light">
					<div className="col-12">
						<span>
							Inflation is like a living organism that reacts to the economic climate in the country.
							The graph shows how inflation has affected different sectors of the industry over different periods of time. By using this data, you can more easily analyze and assess the risks associated with investments.
							With all these indicators at hand, you can better understand the economic climate in the Czech Republic and invest in destinations with lower risk.
							All data is obtained from official sources and is updated as data is updated.
						</span>
					</div>
				</div>

			</section>
		</div>
	)
}